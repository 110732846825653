"use client";
import React from "react";
import { Icon } from "../../atoms/icon/icon";
import { IconColour } from "../../atoms/icon/icon.types";
import { type BreadcrumbProps } from "./breadcrumb.types";
import { useLanguageDirection } from "../../../hooks/use-language-direction";

export function Breadcrumb({ children }: BreadcrumbProps) {
	const isRTL = useLanguageDirection();
	const mobileChild = React.Children.toArray(children)[children.length - 2];

	return (
		<>
			<nav aria-label="Breadcrumb" className="visible md:hidden px-3">
				<ul className="flex h-6 md:h-12">
					<li className="flex items-center text-grey-40">
						<span className="flex">
							<Icon
								name={isRTL ? "chevron_left" : "chevron_right"}
								size={16}
								colour={IconColour.NeutralGrey40}
							/>
						</span>
						{mobileChild}
					</li>
				</ul>
			</nav>
			<nav
				aria-label="Breadcrumb"
				className="hidden max-content mx-auto md:block md:px-4 lg:px-0"
			>
				<ul className="flex relative h-6 md:h-12 prose-li-span:first:hidden">
					{React.Children.map(children, (child, key: number) => (
						<li className="flex items-center text-grey-40" key={key}>
							<span
								{...(key === 0
									? { className: "hidden" }
									: { className: "flex" })}
							>
								<Icon
									name={isRTL ? "chevron_left" : "chevron_right"}
									size={16}
									colour={IconColour.NeutralGrey40}
								/>
							</span>
							{child}
						</li>
					))}
				</ul>
			</nav>
		</>
	);
}
